<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <acountNav></acountNav>
                <div class="acount-box">
                    <div class="acount-header">
                        <div class="acount-header-item">消息提醒</div>
                        <div class="lists-operation">
                            <span class="look-item">全部已读</span>
                            <span class="delete-item">删除</span>
                        </div>
                    </div>
                    <div class="acount-main">
                        <div class="notice-lists">
                            <div class="notice-item" v-for="(item , index) in noticeLists" :key="index">
                                <img src="../../static/icon/my-notice-icon.png">
                                <div class="notice-item-center">
                                    <span class="item-title">系统通知</span>
                                    <span class="item-content">您的账号异地登录了2024-01-21 14:25:30</span>
                                </div>
                                <span class="item-time">01-21</span>
                            </div>
                            <noData v-if="noticeLists.length === 0" title="暂无数据"></noData>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import noData from '@/components/noData.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import acountNav from '@/components/acountNav.vue'
export default {
    name: "notice",
    components: {
        pageHeader,
        pageFooter,
        acountNav,
        noData
    },
    data() {
        return {
            noticeLists:[]
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.acount-box {
    width: 888px;
    background-color: #ffffff;
    min-height: calc(100vh - 120px - 16px - 28px);
    margin-top: 16px;
    margin-bottom: 28px;
}

.acount-header {
    width: 888px;
    border-bottom: 1px solid #E8E8E8;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
}

.acount-header-item {
    height: 60px;
    margin-right: 70px;
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.lists-operation{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.lists-operation span{
    font-size: 16px;
    margin-left: 24px;
    cursor: pointer;
}
.lists-operation .look-item{
    color: #7E7E7E;
}
.lists-operation .delete-item{
    color: #DD0722;
}

.acount-main {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.notice-lists{
    width: 888px;
    padding: 0 20px;
}
.notice-item{
    width: 848px;
    height: 120px;
    border-bottom: 1px solid #CDD2D9;
    padding: 24px 0;
    display: flex;
    align-items: flex-start;
}
.notice-item img{
    width: 66px;
    height: 66px;
}
.notice-item .notice-item-center{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}
.notice-item .notice-item-center .item-title{
    color: #63676D;
    font-size: 17px;
    margin-top: 6px;
}
.notice-item .notice-item-center .item-content{
    margin-top: 10px;
    color: #63676D;
    font-size: 15px;
}
.item-time{
    color: #8C9198;
    font-size: 14px;
}
</style>
